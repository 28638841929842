import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "select"];

  submit(event) {
    this.inputTargets.forEach((input) => {
      if (!input.value.trim()) {
        input.disabled = true;
      }
    });

    this.selectTargets.forEach((select) => {
      if (!select.value.trim()) {
        select.disabled = true;
      }
    });
  }
}