// app/javascript/controllers/variants_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  toggleHiddenFields(event) {
    const hiddenFields = document.querySelectorAll(".variant-hidden-field");
    hiddenFields.forEach((field) => {
      field.disabled = !event.target.checked; // Enable or disable hidden fields
    });
  }
}