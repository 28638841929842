import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "paymentButtons",
    "paymentOption",
    "paymentType",
    "paymentButton",
    "disclaimer",
    "paymentForm"
  ];
  static values = {
    enableDisclaimer: Boolean // Passed from the data attribute
  };

  togglePayment(event) {
    const paymentType = event.currentTarget;
    const option = paymentType.dataset.option;

    this.paymentOptionTargets.forEach(element => {
      const isVisible = element.dataset.option === option;

      if (isVisible) {
        element.classList.remove("hidden");
        element.classList.add("visible");
        this.paymentTypeTarget.value = element.dataset.type;
      } else {
        element.classList.remove("visible");
        element.classList.add("hidden");
      }
    });

    if (!paymentType.classList.contains("active")) {
      this.clearActiveButtons();
      paymentType.classList.add("active", "button-transition");
    }
  }

  clearActiveButtons() {
    this.paymentButtonTargets.forEach(button =>
      button.classList.remove("active")
    );
  }

  selectDefaults() {
    const toggleButton = this.paymentButtonTargets[0];
    toggleButton.classList.add("active", "button-transition");
    this.paymentOptionTargets.forEach(element => {
      const isVisible = element.dataset.option === toggleButton.dataset.option;
      if (isVisible) {
        element.classList.remove("hidden");
        element.classList.add("visible");
        this.paymentTypeTarget.value = element.dataset.type;
      }
    });
  }


  proceedToPaymentOptions() {
    this.disclaimerTarget.classList.add("hidden");
    this.showPaymentOptions();
  }

  hidePaymentOptions() {
    this.paymentFormTarget.classList.add("hidden");
  }

  showPaymentOptions() {
    this.paymentFormTarget.classList.remove("hidden");
  }

  connect() {
    if (!this.enableDisclaimerValue) {
      this.showPaymentOptions(); // Skip disclaimer if disabled
    } else {
      this.hidePaymentOptions();
    }

    this.selectDefaults();
  }
}
