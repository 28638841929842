// app/javascript/controllers/toggle_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "toggle"]; // Define targets for the select element and fields to toggle

  connect() {
    this.toggleFields(); // Initialize toggle based on current select value
  }

  toggleFields() {
    const selectedValue = this.selectTarget.value;
    const shouldShow = selectedValue === "true" || selectedValue === "yes" || selectedValue === "Custom" || selectedValue === "Active"; // Customize for specific values

    this.toggleTargets.forEach((target) => {
      target.classList.toggle("d-none", !shouldShow);
    });
  }

  // Listen for changes on the select element
  change() {
    this.toggleFields();
  }
}