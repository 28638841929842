// app/javascript/controllers/load_more_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]
  static values = { url: String, totalPages: Number }

  loadMore(event) {
    event.preventDefault()

    const url = this.urlValue
    this.buttonTarget.disabled = true;

    fetch(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    })
    .then(response => response.text())
    .then(html => {
      const listingsContainer = document.getElementById("output-container")
      listingsContainer.insertAdjacentHTML("beforeend", html)
      this.initializeNewInputs()

        // Update the data-load-more-url-value with the next page URL
        const nextPage = url.match(/page=(\d+)/)[1]; // Extract the current page number
        const newPage = parseInt(nextPage) + 1; // Increment page
        const newUrl = url.replace(/page=\d+/, `page=${newPage}`); // Generate new URL
        this.urlValue = newUrl;

        // If we've reached the last page, remove the button
        if (newPage > parseInt(this.totalPagesValue)) {
            this.buttonTarget.remove();
        } else {
            this.buttonTarget.disabled = false; // Re-enable button for the next load
        }
    })
    .catch(() => {
      this.buttonTarget.disabled = false;
    })
  }

  initializeNewInputs() {
    // Find all newly added inputs
    const newInputs = document.querySelectorAll("input")

    newInputs.forEach(input => {
      // Create and dispatch an input event for each new input
      const event = new Event('input', { bubbles: true })
      input.dispatchEvent(event)
    })
  }
}
