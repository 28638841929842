// This is for stores that have to have a single collection present for every category - alabama.co.ke 
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["collectionSelect", "categorySelect", "subCategorySelect"]
  static values = {
    catId: Number,
    subId: Number
  }

  async connect() {
    await this.updateCategories();
    this.updateSubCategories();
  }

  async updateCategories() {
    const collectionId = this.collectionSelectTarget.value;
    const addCategoryLink = document.getElementById("addCategory")

    if (!collectionId) {
        this.categorySelectTarget.innerHTML = '<option value="">Select Category first</option>';
        if (addCategoryLink) {
          addCategoryLink.style.display = "none";
        }
        return;
    }
      
    if (addCategoryLink) {
        addCategoryLink.style.display = "block";
    }
    
    // Add ways to add categories and associate them with a collection

    const data = await this.fetchCategories(collectionId);

    if (data.categories.length > 0) {
        this.subCategorySelectTarget.innerHTML = '<option value="">Select SubCategory first</option>' // to clear the sub subCategories
        if (window.location.href.includes("pos/listings")) {
          const options = data.categories.map(category => {
            const selected = category[0] === this.catIdValue ? 'selected' : '';
            `<option value="${category[0]}" ${selected}>${category[1]}</option>`
          })
          this.categorySelectTarget.innerHTML = options
        } else if (window.location.href.includes("/new_listing") || (window.location.href.includes("/edit_listing"))) {
          const options = [
            '<option value="">Select Option</option>',
            ...data.categories.map(category => {
              const selected = category[0] === this.catIdValue ? 'selected' : '';
              return `<option value="${category[0]}" ${selected}>${category[1]}</option>`;
            })
          ].join('');
        this.categorySelectTarget.innerHTML = options
        } else {
          const options = [
              '<option value="">All SubCategories</option>',
              ...data.categories.map(category => 
                  `<option value="${category[0]}">${category[1]}</option>`
                )
          ].join('');
          this.categorySelectTarget.innerHTML = options
        }
    } else {
        this.categorySelectTarget.innerHTML = '<option value="">No SubCategories found</option>'
    }
  }

  async updateSubCategories() {
    const categoryId = this.categorySelectTarget.value;
    const addSubCategoryLink = document.getElementById("addSubCategory")
  
    if (!categoryId) {
      this.subCategorySelectTarget.innerHTML = '<option value="">Select SubCategory first</option>';
      if (addSubCategoryLink) {
        addSubCategoryLink.style.display = "none";
      }
      return;
    }
    
    if (addSubCategoryLink) {
      addSubCategoryLink.style.display = "block";
    }

    const subCategoryForm = document.getElementById("subCategoryForm")
    if (subCategoryForm) {
      subCategoryForm.action = `/categories/${categoryId}/sub_categories`
    }

    const data = await this.fetchSubcategories(categoryId);

    if (data.sub_categories.length > 0) {
        if (window.location.href.includes("pos/listings")) {
          const options = data.sub_categories.map(subCategory => {
            const selected = subCategory[0] === this.subIdValue ? 'selected' : '';
            `<option value="${subCategory[0]}" ${selected}>${subCategory[1]}</option>`
          })
          this.subCategorySelectTarget.innerHTML = options
        } else if (window.location.href.includes("/new_listing") || (window.location.href.includes("/edit_listing"))) {
          const options = [
            '<option value="">Select Option</option>',
            ...data.sub_categories.map(subCategory => {
              const selected = subCategory[0] === this.subIdValue ? 'selected' : '';
              return `<option value="${subCategory[0]}" ${selected}>${subCategory[1]}</option>`;
            })
          ].join('');
        this.subCategorySelectTarget.innerHTML = options
        } else {
          const options = [
              '<option value="">All SubCategories</option>',
              ...data.sub_categories.map(subCategory => 
                  `<option value="${subCategory[0]}">${subCategory[1]}</option>`
                )
          ].join('');
          this.subCategorySelectTarget.innerHTML = options
        }
    } else {
        this.subCategorySelectTarget.innerHTML = '<option value="">No Sub SubCategories found</option>'
    }
  }

  async fetchCategories(collectionId) {
    const catId = this.catIdValue
    const response = await fetch(`/collections/${collectionId}/categories?selected_cat_value=${catId}`);
    return response.json();
  }

  async fetchSubcategories(categoryId) {
    const subId = this.subIdValue
    const response = await fetch(`/categories/${categoryId}/sub_categories?selected_sub_value=${subId}`);
    return response.json();
  }
}
